import React from "react"

const Logo356 = ({id, space }) => (
  <div id={id} style={{
    height:`${space}px`,
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  }}>
    <div style={{
      width:(space === 80)?`360px`:`230px`,
      height:'100%',
      maxWidth:'100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}></div>
  </div>
)

export default Logo356;