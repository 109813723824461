import React from "react"
import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Logo356 from "./logo356";
import {Link} from "react-scroll";

const Header = class extends React.Component {
  constructor(){
    super()
    this.state = {
      pagePosition:80
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  listenScrollEvent = e => {
    if (window.scrollY === 0) {
      this.setState({pagePosition: 80})
    } else {
      this.setState({pagePosition: 50})
    }
  }

  render(){
    return(
      <header>
        <AppBar position="fixed" color="inherit">
          <Toolbar>
            <Link to="___gatsby" smooth={true} duration={500}>
              <Logo356 id="topLogo" space={this.state.pagePosition}/>
            </Link>
          </Toolbar>
        </AppBar>
      </header>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
