/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import { MuiThemeProvider } from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles'

import orange from '@material-ui/core/colors/orange';
import amber from '@material-ui/core/colors/amber';

let theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: amber,
  },
})

const Layout = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Header/>
          <div id="MainWrapper">
            <main>{children}</main>
            <footer>
              © {new Date().getFullYear()}
            </footer>
          </div>
        </>
      )}
    />
  </MuiThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
